import $ from "jquery"

$(function() {
    const $menubutton = $("#menubutton");
    const $desktopmenu = $("#desktopmenu");
    const $body = $("body");
    $menubutton.on("click", function() {
        const newvisible = !$desktopmenu.hasClass("visible");
        $desktopmenu.toggleClass("visible", newvisible);
        $body.toggleClass("desktopmenuvisible", newvisible);
    });
});